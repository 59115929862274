
a.tile {
  display: block;
  padding: 1.5rem;
  text-decoration: none;
  font-size: 1.4rem;
  background-color: #fff;

  @include respondMf(sm) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  @include respondMf(md) {
    font-size: 1.6rem;
  }

  @include respondMf(lg) {
    padding: 2rem;
  }

  @include respondMf(xlg) {
    padding: 2rem 4rem;
  }

  &:link,
  &:visited {
    color: $color-1;
  }

  strong {
    font-weight: 700;
  }

  &:not(:last-of-type) {
    margin: 0 0 .5rem;
  }

  &.tile--disabled {
    background-color: $color-3;

    &:link,
    &:visited {
      color: $color-4;
    }
  }
}

.tile__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 .5rem;

  @include respondMf(sm) {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    margin: 0 0 1rem;
  }

  @include respondMf(md) {
    justify-content: flex-start;
  }

  @include respondMf(lg) {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
    margin: 0;
  }

  .label {
    margin-left: .5rem;
  }
}

.tile__text {
  display: block;
  margin: 0 0 1rem;

  @include respondMf(sm) {
    margin: 0;
  }

  @include respondMf(lg) {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
  }
}

.tile__footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-transform: uppercase;
  font-weight: 400;
  transition: .25s color;
  will-change: color;
}

.tile__svg {
  flex: 0 0 3rem;
  width: 3rem;
  max-width: 3rem;
  height: 3rem;
  margin-left: 1rem;

  .st0 {
    fill: $color-1;
    transition: .25s fill;
    will-change: fill;

    .tile--disabled & {
      fill: $color-4;
    }
  }
}

.tile:hover {

  .tile__footer {
    color: $color-2;
  }
  .tile__svg .st0 {
    fill: $color-2;
  }
}
