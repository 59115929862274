
.form-input {
  width: 100%;
  padding: 0 2rem;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: .05em;
  line-height: 1;
  height: 6.2rem;
  border: .1rem solid $form-border-color;
  border-radius: 1rem;
  background-color: rgb(246, 246, 246);

  @include respondMf(md) {
    font-size: 1.6rem;
  }

  &,
  &::placeholder {
    color: $form-text-color;
  }
}

.form-input:not(.form-input--error):focus {
  border-color: $download-link-hover-color;
}

.form-input--error {
  border-color: $form-error-color;
}

.form-input--textarea {
  height: 16.5rem;
  padding: 2rem;
  line-height: 1.286;
  resize: none;
}

