
.number {
  margin: 0 0 4rem;
  padding: 1rem 0 0;
  text-align: center;

  @include respondMf(md) {
    padding: 2rem 0 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 8rem;
    height: .3rem;
    background-color: #fff;

    @include respondMf(md) {
      height: .6rem;
    }
  }
}

.number__title {
  font-family: $font-family-2;
  font-weight: 700;
  font-size: 1.8rem;
  color: $color-2;

  @include respondMf(md) {
    font-size: 3.6rem;
  }
}

.number__num {
  font-size: 5rem;

  @include respondMf(md) {
    font-size: 10rem;
  }
}

.number__text {
  font-size: 1.6rem;
  font-weight: 300;
}
