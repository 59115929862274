
.pagination {
  user-select: none;
}

.pagination__list {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0 -1.5rem;
  padding: 0;
}

.pagination__item {
  position: relative;
}

.pagination__item:not(.pagination__item--previous-page) + .pagination__item:not(.pagination__item--next-page) {

  &::after {
    content: '';
    position: absolute;
    bottom: .3rem;
    right: 100%;
    width: .1rem;
    height: 1rem;
    background-color: rgba($pagination-color-current, .2);
  }
}

.pagination__item--next-page,
.pagination__item--previous-page {
  top: .2rem;
}

.pagination__link {
  display: inline-block;
  padding: 0 1.5rem;
  text-decoration: none;
  font-size: $pagination-size;
  letter-spacing: .05em;
  line-height: 1;
  transition: .25s color;
  will-change: color;

  &:link,
  &:visited {
    color: $pagination-color;
  }

  &:hover,
  &:active {
    color: $pagination-color-hover;
  }

  .pagination__item--is-active & {
    pointer-events: none;

    &:link,
    &:visited {
      color: $pagination-color-current;
    }

    &:hover,
    &:active {
      color: $pagination-color-current;
    }
  }
}

.pagination__path {
  transition: .25s fill;
  will-change: fill;
  fill: $pagination-color;

  .pagination__link:hover & {
    fill: $pagination-color-hover;
  }
}
