
.sub-header {
  position: relative;
  padding: 3rem 0;

  @include respondMf(xlg) {
    padding: 9rem ;
  }
}

.sub-header__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  font-family: "object-fit:cover;object-position: center center;";
}

/*
.sub-header::before {
  content: '';
  display: block;
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: -14rem;
  transform: translate(0, -50%);
  width: 8rem;
  height: 12rem;
  background-image: url('#{$path-image}/object-05.webp');
  background-repeat: no-repeat;
  background-size: 100% auto;

  @include respondMf(md) {
    bottom: -31rem;
    width: 18rem;
    height: 26rem;
  }

  @include respondMf(lg) {
    bottom: -38rem;
    width: 20rem;
    height: 30rem;
  }

  @include respondMf(xlg) {
    bottom: -38rem;
    width: 23rem;
    height: 34rem;
  }

  @include respondMf(desktop) {
    bottom: -44rem;
    width: 39rem;
    height: 56rem;
  }

  .no-webp & {
    background-image: url('#{$path-image}/object-05.png');
  }
}
 */

.sub-header .col--xlg-4 {

  @include respondMf(xlg) {
    height: 1px;
  }
}
