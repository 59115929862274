
// paths
$path-image: '../img';

// mf - mobile first
$grid-breakpoints-mf: (
        xs: 25em, // 400px
        sm: 36em, // 576px
        md: 48em, // 768px
        lg: 62em, // 992px
        xlg: 75em, // 1200px
        sm-desktop: 90em, // 1440px
        desktop: 1800px
);

// fonts
$font-family-1: 'Open Sans', sans-serif;
$font-family-2: 'Roboto Condensed', sans-serif;

// colors
$color-1: #0b3a76;
$color-2: #ffb800;
$color-3: #d9d9d9;
$color-4: #8b8b8b;
$color-5: #369ee1;
$color-6: #0b3975;
$color-7: #fabe0e;

$color-8: #42ac71;
$color-9: #e86a6a;

// buttons (filled, stroke)
$button-size: 1.6rem;
$button-color: #fff;
$button-bg-color: $color-2;
$button-bg-hover-color: $color-4;

// links
$link-color: $color-2;
$link-hover-color: $color-4;
$link-size: 1.2rem;

// custom checkbox & radio
$checkbox-size: 1.6rem;
$checkbox-color: #fff;
$checkbox-inactive-color: #fff;
$checkbox-active-color: $color-2;

// pagination & indicators
$pagination-color: $color-2;
$pagination-color-hover: $color-4;
$pagination-color-current: $color-3;
$pagination-size: 1.6rem;

// accordion
$accordion-title-color: $color-2;
$accordion-text-color: $color-3;
$accordion-border-color: $color-5;
$accordion-hover-color: $color-4;

// tabs
$tabs-unchecked-color: $color-2;
$tabs-checked-color: $color-3;
$tabs-border-color: $color-7;
$tabs-size: 1.6rem;

// selects
$select-color: $color-2;
$select-text-color: $color-3;
$select-size: 1.4rem;

// cookies
$cookies-color: $color-2;

// popups & modals
$popup-color: $color-2;
$popup-var-2-color: $color-8;
$popup-var-3-color: $color-9;

// tables
$table-head-color: $color-2;
$table-border-color: $color-7;
$table-text-color: $color-3;

// forms
$form-label-color: #fff;
$form-border-color: #fff;
$border-focus-color: $color-4;
$form-error-color: $color-2;
$form-text-color: $color-1;

// download button
$download-link-color: $color-2;
$download-link-hover-color: $color-4;
$download-text-color: $color-3;
$download-border-color: $color-7;
