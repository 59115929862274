/* The switch - the box around the slider */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 5rem;
  height: 1rem;
  margin-left: 4rem;

  @include respondMf(sm) {
    float: right;
    margin-left: 0;
    margin-right: 4.5rem;
  }
}

.toggle-switch::before,
.toggle-switch::after {
  position: absolute;
  padding: 0 1rem;
  font-size: 1.2rem;
  font-weight: 300;
  color: $color-3;
}

.toggle-switch::before {
  content: attr(data-before);
  right: 100%;
}
.toggle-switch::after {
  content: attr(data-after);
  left: 100%;
}

/* Hide default HTML checkbox */
.toggle-switch__check  {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.toggle-switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-1;
  transition: .4s;
}

.toggle-switch__slider::before {
  position: absolute;
  content: '';
  height: 1.6rem;
  width: 2.5rem;
  left: -.3rem;
  bottom: -.3rem;
  background-color: white;
  transition: .4s;
}

.toggle-switch__check:checked + .toggle-switch__slider::before {
  transform: translateX(3.1rem);
}