
.box {
  position: relative;
  padding: 4rem 3rem 3rem;
  box-shadow: 0 1rem 7.6rem 0 rgba(2, 2, 2, 0.15);
  background-color: #fff;

  @include respondMf(md) {
    padding: 7rem;
  }
}

.box--1 {
  margin: -2rem 0 0;
  z-index: 1;

  @include respondMf(sm) {
    margin: -3rem 0 0;
  }

  @include respondMf(md) {
    margin: -6rem 0 0;
  }

  @include respondMf(lg) {
    margin: -3rem 0 0;
  }
}

.box--2 {
  margin: -8rem 0 2rem;

  @include respondMf(lg) {
    margin: -18rem 0 2rem;
  }

  @include respondMf(xlg) {
    margin: -40rem 0 7rem;
  }
}

.box--3 {
  margin: 0 0 4rem;

  @include respondMf(sm) {
    margin: -6rem 0 4rem;
  }

  @include respondMf(md) {
    margin: -9rem 0 4rem;
  }

  @include respondMf(lg) {
    margin: -26rem 0 4rem;
  }

  @include respondMf(xlg) {
    margin: -22rem 0 10rem;
    padding: 17rem 7rem;
  }

  h2 {
    margin: 0;
  }
}

.box--4 {
  margin: -4rem 0 0;
  z-index: 1;

  @include respondMf(sm) {
    margin: -6rem 0 0;
  }
}

.box--5 {
  margin: -8rem 0 4rem;

  @include respondMf(xlg) {
    max-width: 49rem;
    margin: -45rem 0 4rem;
  }

  ol {
    margin: 0;
  }
}

.box--6 {
  margin: 0 0 -6rem;

  @include respondMf(md) {
    margin: 7rem 0 -6rem;
    padding: 7rem 3.5rem;
  }

  @include respondMf(xlg) {
    min-width: 55rem;
    padding: 7rem;
    margin: 9rem 0 -6rem;
  }

  .col--6:not(:last-of-type):not(:nth-last-of-type(2)) .icon-text-vertical {

    @include respondMf(lg) {
      margin: 0 0 5rem;
    }
  }
}

.box--7 {
  margin: 0 0 -13rem;
  z-index: 1;

  @include respondMf(md) {
    margin: 0 0 -18rem;
  }

  @include respondMf(xlg) {
    margin: 0 0 -22rem;
  }

  a.icon-text-horizontal {
    @include respondMf(xlg) {
      padding: 2.5rem 0;
    }
  }

  a.icon-text-horizontal:last-of-type .icon-text-horizontal__picture {
    @include respondMf(xlg) {
      position: relative;
      top: .2rem;
    }
  }
}

.box--c-1 {
  background-image: linear-gradient(to top right, rgb(255,204,0) 0%, rgb(252,219,89) 100%);
}

.box--c-2 {
  background-color: $color-5;
}

.box--decoration {
  position: relative;
}

.box--decoration::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 25rem;
  height: .6rem;
  background-color: $color-2 ;
}

.box--decoration-c-2::before {
  background-color: $color-6;
}
