
.btn {
  display: inline-block;
  margin: 0 0 3rem;
  padding: 1em 1.6em;
  border-radius: 2.5rem;
  border: .1rem solid transparent;
  text-decoration: none;
  text-transform: uppercase;
  font-size: $button-size;
  letter-spacing: .05em;
  line-height: 1;
  user-select: none;
}

.btn + .btn {

  @include respondMf(sm) {
    margin-left: 3rem;
  }
}

.btn--filled {
  border-color: $button-bg-color;
  background-color: $button-bg-color;
  transition: .25s background-color;
  will-change: background-color;

  &,
  &:link,
  &:visited {
    color: $button-color;
  }

  &:hover,
  &:active {
    background-color: $button-bg-hover-color;
  }
}

.btn--filled-2 {
  text-transform: none;
  font-weight: 700;
  font-size: 1.4rem;
  background-color: $color-7;

  @include respondMf(xlg) {
    padding: 1em 2.6em;
    font-size: 1.8rem;
  }

  &:link,
  &:visited {
    color: $color-1;
  }

  &:hover {
    background-color: $color-1;
    color: #fff;
  }
}

.btn--filled-inverse {
  border-color: $button-color;
  background-color: $button-color;
  transition: .25s background-color, .25s color, .25s border-color;
  will-change: background-color, color, border-color;

  &,
  &:link,
  &:visited {
    color: $button-bg-color;
  }

  &:hover,
  &:active {
    border-color: $button-bg-hover-color;
    color: $button-color;
    background-color: $button-bg-hover-color;
  }
}

.btn--stroke {
  border-color: $button-bg-color;
  transition: .25s border-color, .25s color;
  will-change: border-color, color;

  &,
  &:link,
  &:visited {
    color: $button-bg-color;
  }

  &:hover,
  &:active {
    border-color: $button-bg-hover-color;
    color: $button-bg-hover-color;
  }
}

.btn--stroke-inverse {
  background-color: transparent;
  border-color: $button-color;
  transition: .25s color;
  will-change: color;

  &,
  &:link,
  &:visited {
    color: $button-color;
  }
}

.btn__spinner {
  position: relative;
  top: .2rem;
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  margin: -1rem 0 0 .5rem;
  border-radius: 50%;
  border: .2rem solid transparent;
  animation: rotate .75s linear infinite;

  .btn--filled & {
    border-color: $button-color;
    border-right-color: transparent;
  }

  .btn--stroke & {
    border-color: $button-bg-color;
    border-right-color: transparent;
  }
  .btn--stroke:hover & {
    border-color: $button-bg-hover-color;
    border-right-color: transparent;
  }
}
