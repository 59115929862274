
.gallery-container {
  z-index: 1;
  margin: 0 0 -10rem;

  @include respondMf(xlg) {
    margin: 0 0 -11rem;
  }
}

.gallery {
  position: relative;
}

.gallery__item {
  margin: 0;
  overflow: visible;

  @include respondMf(sm) {
    box-shadow: 0 1rem 7.6rem 0 rgba(2, 2, 2, 0.15);
  }

  @include respondMf(xlg) {
    box-shadow: none;
  }

  a {
    position: relative;
    display: block;
    float: left;
    padding: 2rem;
    background-color: #fff;

    @include respondMf(sm) {
      padding: 1rem;
    }

    @include respondMf(xlg) {
      min-height: 34rem;
      width: 100%;
      padding: 2rem;
      box-shadow: 0 1rem 7.6rem 0 rgba(2, 2, 2, 0.15);
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 25rem;
      max-width: 100%;
      height: .6rem;
      background-color: $color-2 ;
      z-index: 1;

      @include respondMf(sm) {
        height: .3rem;
      }
    }
  }
}

.gallery__img {
  float: left;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
  font-family: 'object-fit:cover;object-position: center center;';
  transition: .25s transform;
  transform: scale(1, 1);
  will-change: transform;

  @include respondMf(xlg) {
    position: absolute;
    top: 2rem;
    left: 2rem;
    width: calc(100% - 4rem);
    height: calc(100% - 4rem);
  }

  .gallery__item:hover & {
    transform: scale(1.05, 1.05);
  }
}

.gallery-container .tns-ovh {

  @include respondMf(xlg) {
    overflow: visible;
  }
}
