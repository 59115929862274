
.nav-toggle {
  display: none;
}

.nav-toggle-button {
  display: block;
  width: 3rem;
  height: 3rem;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(46,44,69)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  cursor: pointer;
  color: transparent;

  position: relative;
  z-index: 1010;

  @include respondMf(lg) {
    width: 4rem;
    height: 4rem;
  }

  @include respondMf(xlg) {
    display: none;
  }

  .nav-toggle:checked + & {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3Csvg id='Outlined' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle/%3E%3Cg id='Fill'%3E%3Cpolygon fill='%232e2c45' points='28.71 4.71 27.29 3.29 16 14.59 4.71 3.29 3.29 4.71 14.59 16 3.29 27.29 4.71 28.71 16 17.41 27.29 28.71 28.71 27.29 17.41 16 28.71 4.71'/%3E%3C/g%3E%3C/svg%3E");
  }
}

.nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(#fff, .95);
  z-index: 1000;

  display: flex;
  align-items: center;
  padding: 8.5rem 2rem 7rem;
  transform: translateX(100%);
  transition: transform .25s;
  will-change: transform;

  @include respondMf(xlg) {
    order: -1;
    position: relative;
    display: block;
    height: auto;
    padding: 0;
    background-color: transparent;
    transform: translateX(0);
    transition: none;
    will-change: auto;
    overflow: visible;
  }

  .nav-toggle:checked ~ & {
    transform: translateX(0);
  }
}

.nav__list {
  display: block;
  height: calc(100% - 3rem);
  max-height: 100%;
  overflow: auto;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;

  @include respondMf(xlg) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    width: auto;
    overflow: visible;
  }
}

.nav__link {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 0 0 4rem;
  padding: 1.5rem 0;
  font-weight: 400;
  font-size: 1.8rem;

  @include respondMf(md) {
    padding: 2.5rem 0;
    font-size: 2.5rem;
  }

  @include respondMf(xlg) {
    margin: 0;
    padding: 0 3.5rem;
    text-transform: none;
    font-size: 1.5rem;
  }

  &::before {
    content: '';
    display: block;
    width: 2.3rem;
    height: .6rem;
    margin-right: 1rem;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='123.31 390.67 595.28 151.7' style='enable-background:new 123.31 390.67 595.28 151.7;' xml:space='preserve'%3E%3Cpath fill='%23FABE0E' d='M664.22,418.08c-57.78,49.63-103.41,88.85-227.28,18.88c-163.63-92.44-240.27-26.6-301.85,26.3 c-13.97,12-15.77,33.31-4.01,47.6c11.75,14.26,32.62,16.11,46.6,4.1c57.78-49.64,103.42-88.84,227.26-18.89 c59.49,33.62,107.47,46.3,147.42,46.3c69.95,0,115.26-38.92,154.46-72.58c13.98-12.01,15.78-33.32,4.02-47.6 C699.06,407.91,678.2,406.08,664.22,418.08z'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
  }
}

.nav__item:last-of-type .nav__link {

  @include respondMf(xlg) {
    padding-right: 0;
  }
}

.nav__item:last-of-type .nav__link::after {

  @include respondMf(xlg) {
    display: none;
  }
}
