
.u-fs-17 {
  font-size: 1.7rem;
}

.u-fs-16 {
  font-size: 1.6rem;
}

.u-fs-14 {
  font-size: 1.4rem;
}

.u-fs-34 {
  font-size: 3.4rem;
}

.u-fs-11 {
  font-size: 1.1rem;
}

.u-fs-10 {
  font-size: 1rem;
}

.u-fs-20 .content p {
  font-size: 1.4rem;

  @include respondMf(md) {
    font-size: 2rem;
  }
}

.u-fw-600 {
  font-weight: 600;
}

.u-fw-700 {
  font-weight: 700;
}

.u-lh-16 {
  line-height: 1.6;
}

.u-lh-2 {
  line-height: 2;
}

.u-c-1 .content p,
.u-c-1 .content h2 {
  color: #fff;
}

.u-c-2 {
  color: $color-2;
}

.u-tt-u {
  text-transform: uppercase;
}
