
.icon-text-vertical__picture {
  height: 9rem;
  margin: 0 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-text-vertical__text {
  max-width: 15rem;
  margin: 0 auto;
  text-align: center;

}

.content.icon-text-vertical__text p {
  font-size: 1.4rem;

  @include respondMf(md) {
    font-size: 2.1rem;
  }

  @include respondMf(lg) {
    margin: 0;
  }
}
