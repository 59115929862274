
a.icon-text-horizontal {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 1.5rem 0;

  @include respondMf(xlg) {
    max-width: 31rem;
  }

  &:link,
  &:visited {
    color: #fff;
  }

  &:hover,
  &:active {
    color: $color-2;
  }

  &:not(:last-of-type) {
    margin: 0 0 .5rem;
    border-bottom: .2rem solid rgba(#fff, .2);
  }
}

.icon-text-horizontal__picture {
  flex: 0 0 3rem;
  width: 3rem;
  max-width: 3rem;
}

.icon-text-horizontal__text {
  font-size: 1.4rem;

  @include respondMf(md) {
    font-size: 1.7rem;
  }

  strong {
    font-weight: 600;
  }
}


