
.custom-checkbox {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
}

/* Styles for hiding the native checkbox */
.custom-checkbox__check {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

/* Styles for the basic appearance of the custom checkbox */
.custom-checkbox__toggle {
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  flex: 0 0 2.5rem;
  vertical-align: middle;
  cursor: pointer;
}

.custom-checkbox__toggle::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: .3rem;
  border: .1rem solid $checkbox-inactive-color;
  background-color: transparent;

  .custom-checkbox__check:hover ~ & {
    box-shadow: 0 0 0 .2rem rgba($checkbox-inactive-color, 0.25);
  }

  .custom-checkbox__check:checked ~ & {
    background-color: $checkbox-inactive-color;
  }
}

.custom-checkbox__check:checked~.custom-checkbox__toggle::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  $check-color: str-replace('#{$color-1}', '#', '%23');
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='#{$check-color}' stroke-width='1' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'/%3E%3C/svg%3E") center 60% no-repeat;
  background-size: 80%;
  z-index: 5;
}

.custom-checkbox__label {
  display: inline-block;
  vertical-align: middle;
  flex: 1;
  margin: .2rem 0 0 1rem;
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: .01em;
  color: $checkbox-color;
  user-select: none;

  @include respondMf(md) {
    margin: .3rem 0 0 1.5rem;
    font-size: $checkbox-size;
  }

  @include respondMf(xlg) {
    margin: .3rem 0 0 3rem;
  }

  a {
    text-decoration: underline;
  }

  a:link,
  a:visited {
    color: #fff;
  }
}
