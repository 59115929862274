.modal {
  text-align: center;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  padding: 1.5rem;
  background: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__container {
  position: relative;
  background-color: $popup-color;
  padding: 3rem;
  max-width: 100%;
  max-height: 100vh;
  border-radius: 1rem;
  overflow-y: auto;
  box-sizing: border-box;
  box-shadow: 0 1.4rem 3.3rem .7rem rgba(4, 17, 33, 0.24);

  .modal--popup & {
    width: 42rem;
    padding: 10rem 3rem;
  }

  .modal--inverse & {
    background-color: #fff;
  }

  .modal--filled-v2 & {
    background-color: $popup-var-2-color;
  }

  .modal--filled-v3 & {
    background-color: $popup-var-3-color;
  }
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal__close {
  position: absolute;
  right: 2rem;
  top: 2rem;
  font-size: 1.8rem;
  background: transparent;
  border: 0;
  color: #fff;
  cursor: pointer;

  .modal--inverse & {
    color: $popup-color;
  }
}
.modal__close::before { content: "\2715"; }

.modal .content * {
  color: #fff;
}

.modal--inverse .content * {
  color: $popup-color;
}

@keyframes mmfadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes mmfadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes mmslideIn {
  from { transform: translateY(15%); }
  to { transform: translateY(0); }
}

@keyframes mmslideOut {
  from { transform: translateY(0); }
  to { transform: translateY(-10%); }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}
