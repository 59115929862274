
.download-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 5.2rem;
  margin: 0 0 2rem;
  padding: 0 2rem;
  text-decoration: none;
  border: .1rem solid $download-border-color;
}

.download-btn__title {
  position: relative;
  top: .2rem;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: .05em;
  color: $download-text-color;
}

.download-btn__cta {
  position: relative;
  top: .2rem;
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 1.2rem;
  letter-spacing: .05em;
  color: $download-link-color;
  transition: .25s color;
  will-change: color;

  .download-btn:hover & {
    color: $download-link-hover-color;
  }
}

.download-btn__icon {
  flex: 0 0 2rem;
  width: 2rem;
  max-width: 2rem;
  height: 2rem;
  margin-right: 1.5rem;
}

.download-btn__head {
  display: flex;
  align-items: center;
}

.download-btn__svg {
  max-width: 100%;

  path,
  polygon {
    fill: $download-link-color;
    transition: .25s fill;
    will-change: fill;

    .download-btn:hover & {
      fill: $download-link-hover-color;
    }
  }
}
