@keyframes flipdown {
  0% {
    opacity: 0;
    transform-origin: top center;
    transform: rotateX(-90deg);
  }
  5% {
    opacity: 1;
  }
  80% {
    transform: rotateX(8deg);
  }
  83% {
    transform: rotateX(6deg);
  }
  92% {
    transform: rotateX(-3deg);
  }
  100% {
    transform-origin: top center;
    transform: rotateX(0deg);
  }
}

.accordion__title {
  margin: 0;
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: .05em;
  color: $accordion-title-color;
  cursor: pointer;
  transition: .25s color;
  will-change: color;

  .accordion__item:hover & {
    color: $accordion-hover-color;
  }
}

.accordion__inner {
  position: relative;
  overflow: hidden;
  max-height: 80rem;
  margin: 1.5rem 0 0;
  opacity: 1;
  transform: translate(0, 0);
  z-index: 2;
  transition: all 0.25s ease-in-out;

  * {
    margin: 0;
    font-size: 1.4rem;
    letter-spacing: .05em;
    line-height: 1.286;
    color: $accordion-text-color;
  }
}

.accordion {
  list-style: none;
  perspective: 900;
  padding: 0;
  margin: 0;
  border: .1rem solid $accordion-border-color;
}

.accordion__item {
  position: relative;
  padding: 1.5rem 2rem;
  margin: 0;
  animation: flipdown 0.5s ease both;

  &:not(:first-of-type) {
    border-top: .1rem solid $accordion-border-color;
  }
}

.accordion__check {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}

.accordion__check:checked ~ .accordion__inner {
  margin-top: 0;
  max-height: 0;
  opacity: 0;
  transform: translate(0, 50%);
}

.accordion__arrow {
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.accordion__arrow::before,
.accordion__arrow::after {
  content: '';
  position: absolute;
  background-color: $accordion-title-color;
  width: .1rem;
  height: .9rem;
  transition: .25s background-color;
  will-change: background-color;

  .accordion__item &,
  .accordion__check:checked ~ & {
    background-color: $accordion-hover-color;
  }
}

.accordion__arrow::before {
  transform: translate(-.3rem, 0) rotate(45deg);
}
.accordion__arrow::after {
  transform: translate(.3rem, 0) rotate(-45deg);
}
.accordion__check:checked ~ .accordion__arrow::before {
  transform: translate(.3rem, 0) rotate(45deg);
}
.accordion__check:checked ~ .accordion__arrow:after {
  transform: translate(-.3rem, 0) rotate(-45deg);
}
