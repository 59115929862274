

.table {
  border-collapse: collapse;
  font-family: $font-family-1;
}

.table td,
.table th {
  padding: .8em 2.5em;
  text-align: center;
  border: .1rem solid $table-border-color;
}

.table th {
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: .05em;
  color: #fff;
  background-color: $table-head-color;
}

.table td {
  font-size: 1.4rem;
  letter-spacing: .05em;
  color: $table-text-color;
}

// md = 768 (mobile first)
@media only screen and (max-width: 767px) {
  .table--vertical {
    border-bottom: .1rem solid $table-border-color;

    /* Force table to not be like tables anymore */
    &, thead, tbody, th, td, tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    td {
      /* Behave  like a "row" */
      border-bottom: none;
      position: relative;
      padding-left: 40%;
      white-space: normal;
      text-align:left;
    }

    td::before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      width: 35%;
      padding: 0 1.5rem;
      white-space: nowrap;
      text-align: left;
      font-weight: bold;
      color: #fff;
      background-color: $table-head-color;
    }

    /*  Label the data  */
    td::before { content: attr(data-title); }
  }

  .table--horizontal {
    border: .1rem solid $table-border-color;
    border-bottom: none;
    /* Force table to not be like tables anymore */
    &, thead, tbody, th, td, tr {
      display: block;
    }
    td, th {
      border: none;
      border-bottom: .1rem solid $table-border-color;
    }
  }
}