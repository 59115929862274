html {
  font-size: 62.5%; // 1rem = 10px
}

body {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  overflow-x: hidden;
  max-width: 100vw;
  font-family: $font-family-1;
  font-size: 1.6rem;
  font-weight: 300;
  color: $color-1;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

input:focus,
button:focus,
textarea:focus {
  outline: none;
}

textarea,
input,
input[type="text"],
input[type="password"],
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
  //border-radius: 0;
}

a {
  text-decoration: none;
  transition: .25s color;
  will-change: color;

  &:visited,
  &:link {
    color: $link-color;
  }

  &:hover,
  &:active {
    color: $link-hover-color;
  }
}

img {
  max-width: 100%;
}
