
// desktop headline sizes
$headline-sizes: (
        h1: 4rem,
        h2: 3.5rem,
        h3: 2.4rem,
        h4: 3rem,
        h5: 2.4rem,
        h6: 1.8rem
);

// headline colors
$headline-colors: (
        h1: #fff,
        h2: $color-1,
        h3: $color-1,
        h4: $color-1,
        h5: $color-1,
        h6: $color-2
);

$h1-size: map-get($headline-sizes, 'h1');
$h2-size: map-get($headline-sizes, 'h2');
$h3-size: map-get($headline-sizes, 'h3');
$h4-size: map-get($headline-sizes, 'h4');
$h5-size: map-get($headline-sizes, 'h5');
$h6-size: map-get($headline-sizes, 'h6');

// paragraphs
$paragraph-size: 1.6rem;
$paragraph-color: $color-1;

.content h1 {
  margin: 0 0 3rem;
  font-family: $font-family-2;
  font-size: calc(#{$h1-size} - #{$h1-size}*.333);
  font-weight: 400;
  color: map-get($headline-colors, 'h1');

  @include respondMf(md) {
    font-size: $h1-size;
  }

  strong {
    font-weight: 700;
  }
}

.sub-header .content h1 {
  color: $color-1;

  @include respondMf(xlg) {
    font-size: 5.5rem;
  }
}

.content h2 {
  margin: 0 0 2rem;
  font-family: $font-family-2;
  font-size: calc(#{$h2-size} - #{$h2-size}*.333);
  color: map-get($headline-colors, 'h2');
  font-weight: 400;

  @include respondMf(md) {
    font-size: $h2-size;
  }

  @include respondMf(lg) {
    margin: 0 0 4rem;
  }

  strong {
    font-weight: 700;
  }
}

.h2--lg .content h2 {
  font-size: calc(5rem - 5rem*.333);

  @include respondMf(md) {
    font-size: 5rem;
  }
}

.content h3 {
  margin: 0 0 1rem;
  font-family: $font-family-2;
  font-size: calc(#{$h3-size} - #{$h3-size}*.333);
  font-weight: 700;
  color: map-get($headline-colors, 'h3');

  @include respondMf(md) {
    margin: 0 0 2rem;
    font-size: $h3-size;
  }
}

.content h4 {
  margin: calc(#{$h1-size} - #{$h4-size}) 0 3rem;
  font-size: calc(#{$h4-size} - #{$h4-size}*.333);
  color: map-get($headline-colors, 'h4');

  @include respondMf(md) {
    font-size: $h4-size;
  }

  font-weight: 700;
}

.content h5 {
  margin: calc(#{$h1-size} - #{$h5-size}) 0 3rem;
  font-size: calc(#{$h5-size} - #{$h5-size}*.333);
  color: map-get($headline-colors, 'h5');

  @include respondMf(md) {
    font-size: $h5-size;
  }

  font-weight: 700;
}

.content h6 {
  margin: calc(#{$h1-size} - #{$h6-size}) 0 3rem;
  font-size: calc(#{$h6-size} - #{$h6-size}*.333);
  letter-spacing: .05em;
  color: map-get($headline-colors, 'h6');

  @include respondMf(md) {
    font-size: $h6-size;
  }

  text-transform: uppercase;
  font-weight: 700;
}

.content p {
  margin: 0 0 3rem;
  font-size: $paragraph-size;
  line-height: 1.286;
  letter-spacing: .05rem;
  color: $paragraph-color;

  strong {
    font-weight: 700;
  }
}

.content a {
  text-decoration: underline;
  font-size: $link-size;
  letter-spacing: .05em;
  transition: .25s color;
  will-change: color;

  &:link,
  &:visited {
    color: $link-color;
  }

  &:hover,
  &:active {
    color: $link-hover-color;
  }
}

// lists (ul, ol)
$list-size: 1.6rem;
$list-color: $color-1;
$list-point-color: $color-2;

.content ul {
  margin: 0 0 3rem;
  padding: 0;
  list-style: none;

  li {
    position: relative;
    margin: 0 0 1rem;
    padding: 0 0 0 2rem;
    font-size: $list-size;
    line-height: 1.286;
    letter-spacing: .05rem;
    color: $list-color;
  }

  li::before {
    content: '';
    position: absolute;
    top: .5rem;
    left: 0;
    width: .7rem;
    height: .7rem;
    border-radius: 50%;
    background-color: $list-point-color;
  }
}

.ul-decoration .content ul {

  @include respondMf(md) {
    margin: 0 0 4rem;
  }

  &:last-of-type {
    @include respondMf(md) {
      margin: 0;
    }
  }

  li {
    padding: 0 0 0 3rem;
  }

  li::before {
    top: .1rem;
    width: 2rem;
    height: 2rem;
    background-color: transparent;
    background-image: url('#{$path-image}/icons/i-point.png');
  }

  strong {
    font-weight: 700;
  }
}

.content ol {
  margin: 0 0 3rem;
  padding: 0;
  list-style: none;
  counter-reset: item;

  li {
    position: relative;
    display: flex;
    margin: 0 0 1rem;
    padding: 0;
    font-size: $list-size;
    font-weight: 700;
    line-height: 1.286;
    letter-spacing: .05rem;
    color: $list-color;
  }

  li::before {
    content: counters(item, "");
    counter-increment: item;
    position: relative;
    top: -.4rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 2.8rem;
    width: 2.8rem;
    max-width: 2.8rem;
    height: 2.8rem;
    margin-right: 1rem;
    border-radius: 50%;
    background-color: $list-point-color;
  }
}
