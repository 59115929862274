
.tabs__list {
  display: flex;
  align-items: center;
  list-style: none;
  max-width: 100%;
  margin: 0 -1.2rem;
  padding: 0;

  .tabs__nav--connected & {
    position: relative;
    border-bottom: .1rem solid $tabs-border-color;
  }
  .tabs__nav--connected &::before {
    content: '';
    position: absolute;
    right: 100%;
    bottom: -.1rem;
    height: 35%;
    transform: translate(50%,0);
    width: 2.5rem;
    background-color: #fff;
    z-index: 1;

    @include respondMf(sm) {
      width: 5rem;
    }
  }
}

.tabs__check {
  display: none;
}

.tabs__item {
  position: relative;
  margin: 0 1.2rem;

  .tabs__nav--connected & {
    margin: 0 .6rem;
    @include respondMf(sm) {
      margin: 0 1.2rem;
    }
  }
}

.tabs__item + .tabs__item::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -1.2rem;
  width: .1rem;
  height: 1rem;
  transform: translate(0,-50%);
  background-color: $tabs-border-color;
}

.tabs__nav--rounded .tabs__item--active::before,
.tabs__nav--rounded .tabs__item--active + .tabs__item::before,
.tabs__nav--connected .tabs__item--active::before,
.tabs__nav--connected .tabs__item + .tabs__item::before {
  display: none;
}

.tabs__label {
  display: inline-block;
  padding: .3em .5em;
  border: .1rem solid transparent;
  text-transform: uppercase;
  font-size: $tabs-size;
  cursor: pointer;
  color: $tabs-unchecked-color;
  transition: .25s color, .25s border-color;
  will-change: color, border-color;

  @include respondMf(sm) {
    padding: .9em 2.3em;
  }

  .tabs__check:checked + & {
    color: $tabs-checked-color;
  }

  .tabs__nav--rounded .tabs__check:checked + & {
    border-color: $tabs-border-color;
    border-radius: 2.5rem;
  }

  .tabs__nav--underline & {
    text-decoration: underline;
  }
  .tabs__nav--underline .tabs__check:checked + & {
    text-decoration: none;
  }

  .tabs__nav--connected & {
    position: relative;
    top: .1rem;
    border-top-left-radius: 2.5rem;
    border-top-right-radius: 2.5rem;
    padding: .9em .8em .3em;

    @include respondMf(sm) {
      padding: .9em 2.3em;
    }
  }

  .tabs__nav--connected .tabs__item--active & {
    border-color: $tabs-border-color;
  }
  .tabs__nav--connected .tabs__item--active &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: .5rem;
    transform: translateY(-.2rem);
    background-color: #fff;
  }
}

.tabs__content {
  position: relative;
  z-index: 1;
  display: none;
  padding: 2rem;
}

.tabs__content--active {
  display: block;
  opacity: 0;
  will-change: opacity;
  animation: .25s fadeIn .1s forwards;
}
