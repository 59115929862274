
.form-show-psw {
  position: absolute;
  right: 2rem;
  top: 4.5rem;
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: .05em;
  color: $form-label-color;
  cursor: pointer;
  transition: .25s color;
  will-change: color;

  &:hover {
    color: $border-focus-color;
  }
}
