
// label
$label-color: $color-1;
$label-bg-color: $color-2;
$label-bg-color-2: $color-6;
$label-bg-color-3: $color-4;
$label-size: 1.4rem;

.label {
  display: inline-block;
  margin: 0;
  padding:.3em 1.5em;
  border-radius: 2.5rem;
  font-size: $label-size;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: .05em;
  background-color: $label-bg-color;
  transition: .25s background-color;
  will-change: background-color;
  user-select: none;

  @include respondMf(xlg) {
    padding: .6em 1.5em;
  }

  &,
  &:link,
  &:visited {
    color: $label-color;
  }
}

.label + .label {

  @include respondMf(sm) {
    margin-left: 2rem;
  }
}

.label--v2 {
  background-color: $label-bg-color-2;
}

.label--v3 {
  background-color: $label-bg-color-3;
}

.tile--disabled .label {
  background-color: $color-1;
  color: #fff;
}

