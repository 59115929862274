
.choices {
  margin-bottom: 0;
}

.choices[data-type*="select-one"]::after {
  content: '';
  top: 1.1rem;
  right: 1.3rem;
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 50%;
  border: none;
  background-color: $color-6;
  background-image: url('#{$path-image}/icons/i-arrow.svg');
  background-repeat: no-repeat;
  background-size: .6rem auto;
  background-position: center center;
  transform: rotate(90deg);
  transition: .25s transform;
  will-change: transform;

  @include respondMf(md) {
    width: 2.1rem;
    height: 2.1rem;
    top: 2rem;
    right: 2rem;
  }
}

.choices[data-type*="select-one"].is-open::after {
  border: none;
  margin-top: -.2rem;
  transform: rotate(270deg);
}

.choices[data-type*="select-one"] .choices__inner {
  min-height: .1rem;
  line-height: 3.2rem;
  height: 3.4rem;
  padding: 0 2rem;
  border-radius: 2.7rem;
  font-size: 1.2rem;
  letter-spacing: .05em;
  color: $select-text-color;

  @include respondMf(md) {
    font-size: $select-size;
    height: 5.5rem;
    line-height: 5.5rem;
  }

  .is-open &,
  .is-focused &,
  & {
    border: .1rem solid $select-color;
    background-color: $color-8;
  }

  .darker & {
    color: $color-4;
  }

  .choices--error & {
    border-color: red;
  }
}

.choices-white .choices[data-type*="select-one"] .choices__inner,
.choices-white .choices__list--dropdown {
  background-color: #fff;
}

.choices__list--single {
  padding: 0;
}

.choices__list--dropdown {
  border: none;
  border-radius: 1rem;
  background-color: $color-8;

  .is-open & {
    margin-top: .2rem;
    border: .1rem solid $select-color;
    border-top-width: .1rem;
    box-shadow: 0 2.6rem 4rem 0 rgba(0, 0, 0, 0.1);
  }
}

.choices__list--dropdown .choices__item--selectable {
  min-height: .1rem;
  line-height: 3.6rem;
  height: 3.4rem;
  font-size: 1.2rem;
  color: $select-text-color;

  @include respondMf(md) {
    height: 4.5rem;
    line-height: 4.5rem;
    font-size: $select-size;
  }
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: $select-color;
  color: $color-6;
}

.choices__item:not(:first-of-type) {
  border-top: .1rem solid #d7d7d8;
}

.choices__placeholder + .choices__item {
  border-top: none;
}

.choices__list--dropdown .choices__item {
  padding: 0 2rem;
  transition: .25s background-color;
  will-change: background-color;
}

.choices__inner .choices__item {
  font-weight: 500;
}

.choices__placeholder {
  color: $select-text-color;
  opacity: 1;
}

.choices__list--dropdown .choices__placeholder {
  display: none;
}