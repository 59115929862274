
.footer {
  padding: 2rem 0;
  text-align: center;
  background-color: $color-2;

  @include respondMf(md) {
    padding: 3rem 0;
  }
}

a.footer__text {
  font-family: $font-family-2;
  font-size: 1.5rem;

  @include respondMf(md) {
    font-size: 3rem;
  }

  strong {
    font-weight: 700;
  }

  &:link,
  &:visited {
    color: $color-1;
  }

  &:hover,
  &:active {
    text-decoration: underline;
  }
}
