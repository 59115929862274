
.form-error {
  display: block;
  overflow: hidden;
  max-height: 0;
  font-size: 1.4rem;
  line-height: 1.286;
  letter-spacing: .05em;
  color: $form-error-color;
  transition: .25s max-height;
  will-change: max-height;

  .red-var & {
    overflow: visible;
    //color: $color-17;
  }
}

.vivalid-error {
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
  order: 3;
  display: block;
  margin: .5rem 0 0 2rem;
  font-size: 1.4rem;
  line-height: 1.286;
  letter-spacing: .05em;
  color: $form-error-color !important;
}

.custom-checkbox .vivalid-error {
  margin: .5rem 0 0 0;

  @include respondMf(md) {
    margin: .5rem 0 0 0;
  }
}

.form-error--is-active {
  margin: .5rem 0 0;
  max-height: 5rem;
}

// vivalid error & choices.js
.js-single-select-helper.vivalid-error-input + .vivalid-error {
  position: absolute;
  top: 100%;
}
.js-single-select-helper.vivalid-error-input + .vivalid-error + .choices {
  margin-bottom: 3rem;
}
.js-single-select-helper.vivalid-error-input + .vivalid-error + .choices .choices__inner {
  border-color: $form-error-color;

  .red-var & {
    //border-color: $color-17;
  }
}
