
.header {
  position: relative;
  padding: 8rem 0;

  @include respondMf(md) {
    padding: 17rem 0;
  }

  @include respondMf(lg) {
    padding: 14rem 0;
  }
}

.header__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  font-family: 'object-fit:cover;object-position:center center;';
}
