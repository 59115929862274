.custom-radio {
  cursor: pointer;
}

/* Styles for hiding the native radio button */
.custom-radio__check {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

/* Styles for the basic appearance of the custom radio button */
.custom-radio__toggle {
  position: relative;
  display: inline-block;
  width: 2.1rem;
  height: 2.1rem;
  margin: 0 .2rem;
  vertical-align: middle;
  border: .1rem solid $checkbox-inactive-color;
  border-radius: 50%;

  .custom-radio__check:hover ~ & {
    box-shadow: 0 0 0 .2rem rgba($checkbox-inactive-color, 0.25);
  }
}

/* Styles for the checked state appearance of the custom radio button */
.custom-radio__toggle::after {
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
  margin: auto;
  position: absolute;
  border-radius: 50%;
  background: $checkbox-active-color;
  transition: .25s width, .25s height;
  will-change: width, height;
}
.custom-radio__check:checked ~ .custom-radio__toggle::after {
  width: .7rem;
  height: .7rem;
}

.custom-radio__label {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 1.5rem;
  font-size: $checkbox-size;
  color: $checkbox-color;
  user-select: none;
}