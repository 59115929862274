
.u-flex {
  display: flex;
}

.u-flex--fw-w {
  flex-wrap: wrap;
}

.u-flex--jc-sb {
  justify-content: space-between;
}

.u-flex--jc-c {
  justify-content: center;
}

.u-flex--ai-c {
  align-items: center;
}

.u-flex--jc-fe {
  justify-content: flex-end;
}

@include respondMf(md) {
  .u-md-flex {
    display: flex;
  }
  .u-md-block {
    display: block;
  }
  .u-md-flex--ai-c {
    align-items: center;
  }
}
