// edit default styles of plugin slimselect inserted in main.scss

.ss-main .ss-single-selected,
.ss-main .ss-multi-selected {
  padding: 0 1.5rem;
  border-radius: 0;
  border: .1rem solid $select-color;
  font-size: 1.3rem;
  color: $select-color;
  background-color: #fff;
  max-width: 100%;
  transition: .25s width;
  will-change: width;
}

.ss-main--error .ss-single-selected,
.ss-main--error .ss-multi-selected {
  border-color: $color-7;
}

.ss-main .ss-multi-selected {
  height: auto;
  min-height: 5.2rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.ss-main .ss-single-selected .ss-arrow span {
  padding: .4rem;
  border: solid $color-1;
  border-width: 0 .1rem .1rem 0;
}

.ss-main--error .ss-single-selected .ss-arrow span {
  border-color: $color-7;
}

.ss-main .ss-multi-selected .ss-values .ss-disabled {
  font-size: $select-size;
  font-weight: 500;
  letter-spacing: .05em;
  color: $select-text-color;
}

.ss-main .ss-multi-selected .ss-add {
  flex: 0 0 2rem;
  margin: 0;
  justify-content: center;
  align-items: center;
}

.ss-main .ss-multi-selected .ss-add .ss-plus.ss-cross {
  transform: rotate(0deg);
}

.ss-main .ss-multi-selected .ss-add .ss-plus {
  width: 1.5rem;
  height: 1.8rem;
  margin-right: -2rem;
  background: transparent;
}
.ss-main .ss-multi-selected .ss-add .ss-plus:after {
  display: inline-block;
  width: initial;
  height: initial;
  transform: rotate(45deg);
  padding: .4rem;
  border: solid $select-color;
  border-width: 0 .1rem .1rem 0;
  background: transparent;
  transition: transform .2s, margin .2s;
}

.ss-main .ss-multi-selected .ss-values {
  align-items: center;
}

.ss-main .ss-multi-selected .ss-values .ss-value {
  position: relative;
  margin-right: 1rem;
  padding: 6px 8px 4px 14px;
  border-radius: 2.5rem;
  text-transform: uppercase;
  font-size: $select-size;
  line-height: 1;
  background-color: $select-color;
  color: #fff;
}
.ss-main .ss-multi-selected .ss-values .ss-value .ss-value-delete {
  color: transparent;
}
.ss-main .ss-multi-selected .ss-values .ss-value .ss-value-delete::after {
  content: '\2715';
  color: #fff;
}

.ss-main .ss-content {
  margin: 0;
  border: none;
}

.ss-main .ss-content .ss-list .ss-option {
  display: flex;
  align-items: center;
  height: 5.2rem;
  padding: 0 2rem;
  font-size: $select-size;
  letter-spacing: .05em;
  color: $select-text-color;
  background-color: #f8f8fa;
  transition: .25s background-color;
  will-change: background-color;
}

.ss-main .ss-content .ss-list .ss-option.ss-disabled,
.ss-search{
  background-color: #f8f8fa;
}

.ss-main .ss-content .ss-search {
  height: 5.2rem;
  padding: 0 2rem;
}
.ss-main .ss-content .ss-search input {
  height: 100%;
  padding: 0;
  font-size: $select-size;
  color: $select-text-color;

  &::placeholder {
    color: #8d8d8d;
  }
}
.ss-main .ss-content .ss-search input:focus {
  box-shadow: none;
}

.ss-main .ss-content .ss-list .ss-option:not(:first-of-type) {
  height: 5.2rem;
  border-top: .1rem solid #d7d7d8;
}

.ss-main .ss-content .ss-list .ss-option:hover,
.ss-main .ss-content .ss-list .ss-option.ss-highlighted {
  background-color: $select-color;
}
.ss-main .ss-content .ss-search input {
  border-radius: 0;
  border: none;
  background-color: transparent;
}