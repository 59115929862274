
.picture-container {
  position: relative;
  height: 30rem;
  margin-top: -3rem;

  @include respondMf(lg) {
    height: 40rem;
  }

  @include respondMf(xlg) {
    height: 63rem;
    margin-top: -7rem;
  }
}

.picture-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center bottom;
  font-family: "object-fit:cover;object-position: center center;";
}
