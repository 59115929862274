
.indicators__list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -.5rem;
  padding: 0;
  list-style: none;
}

.indicators__item {
  padding: 0 .5rem;
}

.indicators__link {
  display: inline-block;
  width: .7rem;
  height: .7rem;
  border-radius: 50%;
  border: .1rem solid $pagination-color;
  transition: .25s background-color;
  will-change: background-color;

  .indicators__item--is-active &,
  &:hover {
    background-color: $pagination-color;
  }
}
