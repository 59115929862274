
.section--bg-1 {
  position: relative;
  margin: -4rem 0 0;
  padding: 0 0 6rem;
  background-image: linear-gradient(transparent 0%, $color-1 5%, $color-1 100%);

  @include respondMf(lg) {
    background-image: linear-gradient(transparent 0%, $color-1 50%, $color-1 100%);
  }

  @include respondMf(sm-desktop) {
    margin: -6rem 0 0;
  }
}

.section--bg-1__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.section--bg-1__bg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center top;
  font-family: "object-fit:contain;object-position:center top;";

  @include respondMf(xlg) {
    object-fit: cover;
    font-family: "object-fit:cover;object-position:center top;";
  }
}

.section--1 {

  @include respondMf(lg) {
    padding: 0 0 9rem;
  }
}

.section--1::after {
  content: '';
  display: block;
  position: absolute;
  z-index: 10;
  right: 0;
  top: 37%;
  transform: translate(0, -50%);
  width: 12rem;
  height: 12rem;
  background-image: url('#{$path-image}/object-01.webp');
  background-repeat: no-repeat;
  background-size: 100% auto;

  .no-webp & {
    background-image: url('#{$path-image}/object-01.png');
  }

  @include respondMf(sm) {
    top: 42%;
    transform: translate(0, -50%);
    width: 14rem;
    height: 14rem;
  }

  @include respondMf(md) {
    top: 41%;
    transform: translate(0, -50%);
    width: 20rem;
    height: 22rem;
  }

  @include respondMf(lg) {
    top: 36%;
    transform: translate(0, -50%);
    width: 25rem;
    height: 27rem;
  }

  @include respondMf(xlg) {
    width: 31rem;
    height: 34rem;
  }

  @include respondMf(desktop) {
    width: 46rem;
    height: 51rem;
    top: 31%;
  }
}

.section--1-5 {
  position: relative;
}

.section--1-5::after {
  content: '';
  display: block;
  position: absolute;
  z-index: 10;
  left: 0;
  top: -3%;
  transform: translate(0, -50%);
  width: 7rem;
  height: 15rem;
  background-image: url('#{$path-image}/object-02.webp');
  background-repeat: no-repeat;
  background-size: 100% auto;

  .no-webp & {
    background-image: url('#{$path-image}/object-02.png');
  }

  @include respondMf(sm) {
    top: 0;
    transform: translate(0, -50%);
    width: 9rem;
    height: 20rem;
  }

  @include respondMf(md) {
    top: 2%;
    transform: translate(0, -50%);
    width: 10rem;
    height: 24rem;
  }

  @include respondMf(lg) {
    top: 5%;
    transform: translate(0, -50%);
    width: 15rem;
    height: 33rem;
  }

  @include respondMf(xlg) {
    width: 24rem;
    height: 52rem;
  }
}

/*
.section--1-5::before {
  content: '';
  display: block;
  position: absolute;
  z-index: 10;
  right: 0;
  bottom: 12%;
  transform: translate(0, -50%);
  width: 13rem;
  height: 10rem;
  background-image: url('#{$path-image}/object-03.webp');
  background-repeat: no-repeat;
  background-size: 100% auto;

  .no-webp & {
    background-image: url('#{$path-image}/object-03.png');
  }

  @include respondMf(sm) {
    width: 18rem;
    height: 18rem;
  }

  @include respondMf(md) {
    width: 25rem;
    height: 20rem;
  }

  @include respondMf(lg) {
    width: 35rem;
    height: 30rem;
    bottom: 18%;
  }

  @include respondMf(xlg) {
    width: 48rem;
    height: 38rem;
    bottom: 9%;
  }

  @include respondMf(desktop) {
    width: 66rem;
    height: 54rem;
    bottom: 1%;
  }
}
 */

.section--2 {
  padding: 18rem 0 4rem;

  img {

    @include respondMf(desktop) {
      object-fit: cover;
    }
  }
}

.section--2::after {
  content: '';
  display: block;
  position: absolute;
  z-index: 10;
  left: 0;
  top: 6%;
  transform: translate(0, -50%);
  width: 8rem;
  height: 8rem;
  background-image: url('#{$path-image}/object-04.webp');
  background-repeat: no-repeat;
  background-size: 100% auto;

  .no-webp & {
    background-image: url('#{$path-image}/object-04.png');
  }

  @include respondMf(sm) {
    top: 10%;
    transform: translate(0, -50%);
    width: 10rem;
    height: 10rem;
  }

  @include respondMf(md) {
    top: 13%;
    transform: translate(0, -50%);
    width: 14rem;
    height: 15rem;
  }

  @include respondMf(lg) {
    top: 17%;
    transform: translate(0, -50%);
    width: 18rem;
    height: 19rem;
  }

  @include respondMf(xlg) {
    top: 19%;
    width: 22rem;
    height: 25rem;
  }

  @include respondMf(desktop) {
    top: 6%;
    width: 40rem;
    height: 47rem;
  }
}

.section--3 {
  position: relative;
  padding: 6rem 0;

  @include respondMf(md) {
    padding: 8rem 0;
  }

  @include respondMf(xlg) {
    padding: 18rem 0;
  }

  .tns-outer {

    @include respondMf(sm) {
      box-shadow: 0 1rem 7.6rem 0 rgba(2, 2, 2, 0.15)
    }

    @include respondMf(xlg) {
      box-shadow: none;
    }
  }
}

.section--3::after {
  content: '';
  display: block;
  position: absolute;
  z-index: 10;
  right: 0;
  top: 33%;
  transform: translate(0, -50%);
  width: 12rem;
  height: 15rem;
  background-image: url('#{$path-image}/object-01.webp');
  background-repeat: no-repeat;
  background-size: 100% auto;

  .no-webp & {
    background-image: url('#{$path-image}/object-01.png');
  }

  @media only screen and (max-width: 375px) {
    top: 36%;
  }

  @include respondMf(sm) {
    top: 42%;
    transform: translate(0, -50%);
    width: 14rem;
    height: 17rem;
  }

  @include respondMf(md) {
    top: 41%;
    transform: translate(0, -50%);
    width: 20rem;
    height: 22rem;
  }

  @include respondMf(lg) {
    top: 50%;
    transform: translate(0, -50%);
    width: 17rem;
    height: 21rem;
  }

  @include respondMf(xlg) {
    width: 25rem;
    height: 34rem;
  }

  @include respondMf(desktop) {
    width: 41rem;
    height: 51rem;
    top: 61%;
  }
}
