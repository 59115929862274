
.form-label {
  display: flex;
  align-items: center;
  margin: 0 0 1rem;
  text-transform: uppercase;
  font-size: 1.6rem;
  line-height: 1.125;
  letter-spacing: .05em;
  color: $color-7;
  user-select: none;
  cursor: pointer;
}

