
.tns-outer {
  position: relative;
  box-shadow: 0 1rem 7.6rem 0 rgba(2, 2, 2, 0.15);

  @include respondMf(sm) {
    box-shadow: none;
  }
}

.tns-ovh {
  margin: 0 0 4rem;

  @include respondMf(xlg) {
    margin: 0 0 6rem;
  }
}

.tns-nav {
  position: absolute;
  bottom: -2rem;
  left: 50%;
  z-index: 5;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, 100%);

  @include respondMf(md) {
    margin: 3rem 0 0;
  }
}

.tns-nav button {
  flex: 0 0 .7rem;
  width: .7rem;
  height: .7rem;
  margin: 0 .3rem;
  padding: 0;
  border-radius: 50%;
  border: .1rem solid $color-2;
  background-color: transparent;
  box-sizing: content-box;
  transition: .25s background-color;
  will-change: background-color;

  &:hover {
    background-color: $color-2;
  }

  &.tns-nav-active {
    background-color: $color-2;
  }
}

.tns-item {
  opacity: 0;
  transition: .25s opacity;
}

.tns-slide-active {
  opacity: 1;
}
