.alert {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0 0 1.5rem;
  padding: 0.5rem 1.5rem;
  z-index: 10000;

  @include respondMf(md) {
    margin: 0 0 2rem;
  }

  @include respondMf(xlg) {
    padding: 1rem 2rem;
  }

  &.hide {
    display: none;
  }
}

.alert-inner {
  width: 100%;
  max-width: 70rem;
  display: flex;
  justify-content: center;
  background-color: $color-1;
  color: #fff;
  padding: 1rem;

  p {
    width: 100%;
    text-align: center;
    margin: 0;
  }
}

.alert-close {
  margin: 0 0.5rem;
  transition: 0.3s all;

  &:hover,
  &:focus {
    opacity: 0.5;
  }
}

.alert__icon {
  flex: 0 0 2.8rem;
  width: 2.8rem;
  max-width: 2.8rem;
  margin: 0 1.5rem 0 0;
  padding: 1rem 0 0;

  @include respondMf(xlg) {
    flex: 0 0 4.8rem;
    width: 4.8rem;
    max-width: 4.8rem;
    margin: 0 2.5rem 0 0;
  }
}

.content .alert__text {
  margin: 0;
  line-height: 1.6;
  font-weight: 700;

  @include respondMf(xlg) {
    line-height: 2;
  }
}
